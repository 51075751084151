import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"

import Accordion from "../../components/template-partials/global-components/accordion/accordion"
import SupportSubmenu from "../../components/template-partials/support/support-submenu"
import InlineInfocard from "../../components/template-partials/global-components/inline-infocard"
import headingBg from "../../images/7.0-headerbg.jpg"

import {UpNextCard} from "../../components/template-partials/global-components/footer-cards"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faDownload } from "@fortawesome/free-solid-svg-icons";
import VideoPlayer from "../../components/VideoPlayer"


import dlheading from "../../images/dl-heading.png";
import dllarge from "../../images/dl-large.png";
import dlkiss from "../../images/dl-kiss.png";
import pbi from "../../images/PlaybookIcon.png";
import magnifypb from "../../images/magnifyplaybook.png";
import support from "../../images/supportgroup.png";
import riplay from "../../images/Resources-Icon.png";
import therapyChart from "../../images/therapy-chart.png";
import magnifylg from "../../images/magnify-lg.png";
import bonescanLg from "../../images/bonescan-lg.png";
import questionsilo from "../../images/1.2.0_questionsologd.png";
import leahYounger from "../../images/Leah-younger.png";
import icoDrop from "../../images/ico-drop.png"
import icoStem from "../../images/ico-stem.png"
import icoScal from "../../images/ico-scalpel.png"
import icoAnti from "../../images/ico-anti.png"
import icoRad from "../../images/ico-rad.png"
import leahOlder from "../../images/Leah-Older.jpg";
import rocket from "../../images/ZaraRocket2x.jpg";
import zaraFamily from "../../images/Zara-family@2x.jpg";
import zaraSchool from "../../images/Zara-school@2x.jpg";
import zaraMeditate from "../../images/Zara-Meditate@2x.jpg";
import zaraDance from "../../images/Zara-dance@2x.jpg";
import zaraMom from "../../images/Zara-Mom@2x.jpg";
import zaraScooter from "../../images/Zara-scooter_1@2x.jpg";
import zaraBed from "../../images/Zara-Bed@2x.jpg";
import zaraKono from "../../images/Zara-kono-2@2x.jpg";
import zaraKonoCu from "../../images/Zara-Kono-cu1@2x.jpg"

import bigAdventures from "../../images/7.4_the-big-adventures-of-little-skivolo.jpg";
import antiBodyBook from "../../images/3.4.1_antibodybook.jpg";
import bookOfFun from "../../images/7.4_little-skivolo-big-book-of-fun.jpg";
import zara from "../../images/Zaratakesoff_v23.jpg";

import Slider from "react-slick-slider"
import SliderNextArrow from "../../components/template-partials/slider-components/slider-next-arrow"
import SliderPrevArrow from "../../components/template-partials/slider-components/slider-prev-arrow"

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from '../../components/variables.module.scss';

const BravingNeuroBlastomaPage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: SliderNextArrow,
    prevArrow: SliderPrevArrow,
    responsive: [
      { breakpoint: 1000000, settings: 'unslick' },
      { breakpoint: 1024, settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }},
    ]
  };
  return (
    <Layout>
      <SEO title="Braving Neuroblastoma with Devon and Leah Still" description="Braving Neuroblastoma with former NFL player Devon Still and his daughter Leah, a pediatric high-risk neuroblastoma survivor." />

      <InteriorPageHeader backgroundImage={headingBg} roundImage={dlheading} alt="How is Neuroblastoma Diagnosed?">
        <h1 className="section__heading section__heading--green h1">Braving Neuroblastoma</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Support`}>
              <SupportSubmenu/>
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons/>
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <h2 className="h2 tc-barney jost-med">Braving Neuroblastoma with Devon <br className="show-for-large"/>and Leah Still</h2>
                <p>United Therapeutics Oncology is proud to partner with former NFL player Devon Still and his daughter Leah, a pediatric high-risk neuroblastoma survivor, to launch <strong><em>Braving Neuroblastoma</em></strong>. This educational initiative supports and provides comprehensive resources to families touched by neuroblastoma, no matter where they are on the patient journey—newly diagnosed, in treatment, or after treatment and beyond.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner">
          <div className="row row-75">
            <div className="columns">
              <h2 className="h2 tc-barney jost-med">Still Standing Together</h2>
              <VideoPlayer
                embedId="871112390"
                title="Still Standing Together"
                data="h=bd44571455"
                className="left-Video-Player"
              />
            </div>
          </div>
        </div>
        <div className="row row--inner lg_mt-2">
          <div className="columns">
            <p>Learn more about the neuroblastoma journey by reading about Devon and Leah's experiences and downloading the Braving Neuroblastoma resources. </p>
            <ul className="ul--standard lg_mt-1">
              <li><a href="#devons-journey"><FontAwesomeIcon icon={faLink}/> A parent's perspective–Read about Devon's journey</a></li>
              <li><a href="#leahs-journey"><FontAwesomeIcon icon={faLink}/> A survivor’s perspective–Read about Leah's journey </a></li>
              <li><a href="#resources"><FontAwesomeIcon icon={faLink}/> Download Braving Neuroblastoma resources</a></li>
            </ul>
          </div>
        </div>
      </Section>

      <Section id="devons-journey" style={{backgroundColor: SassVars.paleBlue}}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">A parent's perspective–Read about Devon's journey</h3>
          </div>
        </div>
        <div className="row row--inner align-justify lg_mt-3 small-col-reverse">
          <div className="columns mt-2">
            <h3 className="h4 tc-viridian jost-med">In June of 2014, as Devon Still fought for his spot on the Cincinnati Bengals, his daughter, Leah, fought for her life.</h3>
            <p className="mt-2"> Leah, at the young age of 4, was diagnosed with high-risk pediatric neuroblastoma, a rare childhood cancer that mostly affects young infants and children under the age of 5. This devastating disease is the third-most common childhood cancer in children less than 15 and accounts for 15 percent of childhood cancer deaths. They were told that Leah had a 50-50 chance of survival. </p>
          </div>
          <div className="columns small-12 large-3 text-center large-text-right">
            <img src={dllarge} alt="Devon and Leah Still" width="245" height="auto"/>
          </div>
        </div>
        <div className="row row--inner align-justify lg_mt-3 mt-3">
          <div className="columns">
            <h3 className="h4 tc-viridian jost-med">Devon recalled that time to be a whirlwind of emotions and a race to learn as much as possible, as he faced the task of making many important decisions.</h3>
            <p className="mt-2">Up until then, he had always felt prepared for whatever had come his way and for the first time, he didn’t know what was going to happen or what the “right” decision was. He was new to the cancer community and had not known any other families impacted by childhood cancer then. He did everything he could to educate himself by reading as much information as he could lay his hands on and preparing for every doctor’s visit with questions. He knew that Leah’s best hope was his ability to be the strongest advocate for her and to make decisions in an informed way.</p>
          </div>
        </div>
        <div className="row row--inner align-justify lg_mt-3">
          <div className="columns small-12 large-3 text-center large-text-left mt-2">
            <img src={dlkiss} alt="Devon and Leah Still Embracing" width="245" height="auto"/>
          </div>
          <div className="columns mt-3">
            <h3 className="h4 tc-viridian jost-med">Following extensive personal reading and multiple consultations with Leah’s care team, Devon made the decision to enroll Leah in a clinical trial for a treatment that specifically targeted neuroblastoma cells. </h3>
            <p className="mt-2">At the same time, he had Leah start an extensive treatment regimen that included chemotherapy, surgery, radiation, and additional experimental treatments. Unfortunately, the disease was still spreading. He was undeterred by the outcome and made the decision to enroll her in a second clinical trial shortly after. This time, the study also included the use of an antibody therapy to help find and destroy cancer cells. All in all, Leah had a total of 41 days of chemo, 40 days of antibody therapy, 19 days of radiation, a seven-hour surgery, and a stem cell transplant. On March 25, 2015, 9 months after they first received the devastating news of Leah’s diagnosis, Devon was told by Leah's doctors that she had no evidence of disease (NED) and was officially in remission. </p>
          </div>
        </div>
        <InlineInfocard className="lg_mt-2 mt-2" text="Today, Leah is 13 years old and 8 years cancer-free. Getting to this point was no easy feat and Devon wants to share his game plan for Braving Neuroblastoma with families going through a similar journey."/>
      </Section>

      <Section style={{backgroundColor: SassVars.paleBlue}}>
        <div className="row row--inner align-justify">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">Study Devon's playbook for Braving Neuroblastoma</h3>
          </div>
          <div className="columns shrink show-for-large">
            <img src={pbi} alt="playbook icon" style={{maxWidth: '92px'}}/>
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns">
            <Accordion className="lg_mt-1 mt-1">
              <Accordion.Item isOpen="true">
                <Accordion.Drawer>Play #1. Inform Yourself When Making the Tough Decisions</Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns large-9">
                    <p>One of the toughest decisions Devon found himself making involved treatment for Leah. There are various types of treatments available for children with neuroblastoma, which can be difficult to navigate for newly diagnosed families. Some treatments are considered standard (treatments currently used and FDA-approved), while others are investigational treatments being tested in clinical trials. Because neuroblastoma is a rare cancer, taking part in a clinical trial may be an option for some children. </p>
                  </div>
                  <div className="columns small-12 text-center large-text-right large-3">
                    <img src={magnifypb} width="160" heigh="auto" alt="Inform Yourself When Making the Tough Decisions"/>
                  </div>
                  <div className="columns large-12 lg_mt-1 mt-2">
                    <p>Organizations like the Children’s Oncology Group (COG), a clinical trial organization, are supported by the National Cancer Institute and serves as a cornerstone within the neuroblastoma space as they unite more than 10,000 experts in childhood cancer across 200 pediatric cancer centers in North America, Australia, New Zealand, and Europe. Treatment approaches for newly diagnosed/high-risk neuroblastoma contain similar components, but there can be slight protocol variations due to the increased survival rates using different approaches. Hospitals in the COG network participate in research and data collection through the use of detailed guidelines (protocols). This allows the COG to get answers to important medical and scientific questions much faster than researchers working alone.</p>
                    <p>When Devon learned that Leah qualified for such a trial within the COG network, he decided to move forward.</p>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Drawer>Play #2. Actively Seek Support</Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns large-3 small-12 text-center large-text-left">
                    <img src={support} width="180" heigh="auto" alt="Seek out support and encouragement from physicians, nurses, patient navigators, social workers, and other families impacted by neuroblastoma"/>
                  </div>
                  <div className="columns">
                    <p>Devon knows every family’s journey is different and understands first-hand the pressure caregivers feel to make the right decisions. It’s important during those times to stay strong for your child. He encourages families who have a child with neuroblastoma to seek out support and encouragement from physicians, nurses, patient navigators, social workers, and other families impacted by neuroblastoma, as that support is critical throughout the uncertain, overwhelming journey of neuroblastoma. </p>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Drawer>Play #3. Surround You and Your Child With Available Resources</Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns small-12">
                    <p>A pediatric cancer diagnosis comes with a lot of questions and important decisions that need to be made quickly. At first, it was hard for Devon to know where to even begin or where to look for more information. Open conversations with your child’s medical care team are crucial to understanding treatment options and plans.</p>
                  </div>
                  <div className="columns lg_mt-1 mt-1">
                    <p>Websites such as this one, Neuroblastoma-info.com, also serve as a helpful resource that can help guide families whose child has received a recent diagnosis, is in treatment, or has completed treatment; and provides support for every step along the way with resources like: “Fast Facts for Your Child,” “Questions to Ask Your Doctor,” and even four illustrated story books focused on different steps in the patient journey—there is something for everyone, at every stage of the neuroblastoma journey.</p>
                  </div>
                  <div className="columns small-12 large-3 text-center large-text-right lg_mt-1 mt-2">
                    <img src={riplay} width="200" heigh="auto" alt="Surround You and Your Child With Available Resources"/>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Drawer>Play #4. Be Vigilant With Post-treatment Care</Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns">
                    <p>It’s very important for families who transition out of treatment to know that the pediatric neuroblastoma journey continues in a different way that requires continued careful watching, support, and encouragement. Follow-up appointments are critical following the end of treatment. During these visits, the child will undergo labs, scans, and physical exams, allowing the oncologist to monitor for neuroblastoma and keep a watchful eye on your child’s recovery and development. These visits can:</p>
                    <Slider className="grid-slider" {...settings}>
                      <div className="text-center">
                        <img src={therapyChart} width="80" height="auto" alt="Let families know the child's recovery is going to plan" />
                        <p className="mt-2">Let families know the child's recovery is going to plan</p>
                      </div>
                      <div className="text-center">
                        <img src={magnifylg} width="110" height="auto" alt="Let families know the child's recovery is going to plan" />
                        <p className="mt-2">Help identify short-term, long-term, or late effects of treatment</p>
                      </div>
                      <div className="text-center">
                        <img src={bonescanLg} width="106" height="auto" alt="Let families know the child's recovery is going to plan" />
                        <p className="mt-2">Monitor for health problems that may appear months or years after cancer therapy has ended</p>
                      </div>
                      <div className="text-center">
                        <img src={questionsilo} width="110" height="auto" alt="Let families know the child's recovery is going to plan" />
                        <p className="mt-2">Discuss any questions or concerns they may have</p>
                      </div>
                    </Slider>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Section>

      <Section id="leahs-journey">
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">A survivor’s perspective–Read about Leah's journey</h3>
          </div>
        </div>
        <div className="row row--inner align-justify lg_mt-3">
        <div className="columns small-12 large-3 text-center large-text-left">
          <img src={leahYounger} alt="Devon and Leah Still" width="245" height="auto"/>
        </div>
          <div className="columns mt-2">
            <p>Leah Still’s journey began in June 2014 when she was diagnosed with high-risk neuroblastoma at the young age of 4. This devastating disease is the third-most common childhood cancer in children less than 15 years old and accounts for 15 percent of childhood cancer deaths. At diagnosis, Leah’s doctors gave her a 50-50 chance of survival, and this is when the fight of her life began.</p>
            <p>During Leah’s fight with neuroblastoma, she was enrolled in 2 clinical trials and underwent:</p>
            <div className="row">
              <div className="columns lg_mt-1 mt-1 small-12 large-6">
                <ul className="ul--standard ul--standard-images">
                  <li><span className="img"><img src={icoDrop} alt="Two rounds of chemotherapy" width="18" height="auto"/></span> Two rounds of chemotherapy</li>
                  <li><span className="img"><img src={icoRad} alt="Radiation therapy"/></span> Radiation therapy</li>
                  <li><span className="img"><img src={icoAnti} alt="Antibody therapy" /></span> Antibody therapy</li>
                </ul>
              </div>
              <div className="columns lg_mt-1 small-12 large-6">
                <ul className="ul--standard ul--standard-images">
                  <li><span className="img"><img src={icoScal} alt="Surgery"/></span> Surgery</li>
                  <li><span className="img"><img src={icoStem} alt="Stem cell transplant"/></span> Stem cell transplant</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <InlineInfocard className="lg_mt-2 mt-2" text="On March 25th, 2015, she was told she had no evidence of disease (NED) and was officially in remission. Today, Leah is passionate about supporting and providing guidance to other children impacted by cancer. She also has written a book and raised funds to help other families in need."/>
      </Section>

      <Section>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">Read about what life for Leah has been like Braving Neuroblastoma.</h3>
          </div>
          <div className="columns small-12 large-3 text-center large-text-right">
            <img src={leahOlder} alt="Devon and Leah Still" width="245" height="auto"/>
          </div>
        </div>
        <div className="row row--inner align-justify lg_mt-3">
          <div className="columns">
          <Accordion className="lg_mt-1 mt-1">
            <Accordion.Item isOpen="true">
              <Accordion.Drawer>Leah, we see that you have recently hit your eight-year cancer-free anniversary&mdash;congrats! What have you been up to?</Accordion.Drawer>
              <Accordion.Expanded>
                <div className="columns small-12 large-3 text-center large-text-left">
                  <img src={rocket} width="160" heigh="auto" alt="Zara Rocket Image"/>
                </div>
                <div className="columns lg_mt-2">
                  <p>Yes, thank you! It’s a big milestone and I feel lucky to have hit it. I try to live life like a normal 13-year old girl who goes to school, plays a lot of sports, and plays with her sister.</p>
                </div>
              </Accordion.Expanded>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Drawer>How are you feeling about being in remission?</Accordion.Drawer>
              <Accordion.Expanded>
                <div className="columns">
                  <p>Now that I’m older, I’m more aware of what I’d gone through as a child and the effects it may have on me as I grow up. I’m happy to have now graduated into the survivorship program, but I do understand there is always a chance the cancer may come back — I try not to think about it too much and instead enjoy my family and friends.</p>
                </div>
                <div className="columns small-12 large-3 text-center large-text-right lg_mt-1">
                  <img src={zaraFamily} width="234" heigh="auto" alt="Zara's Family"/>
                </div>
              </Accordion.Expanded>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Drawer>Did you ever feel worried about returning back into “normal” life?</Accordion.Drawer>
              <Accordion.Expanded>
              <div className="columns small-12 large-3 text-center large-text-left">
                <img src={zaraSchool} width="200" heigh="auto" alt="Zara returning to school"/>
              </div>
                <div className="columns large-9">
                  <p>Yes and no. I think it’s always nerve-racking starting a new school and during my treatment journey I moved around a lot, so I was used to change and constantly meeting new people. I was more nervous about people treating me differently because of what I went through. I really wanted to be treated normally.</p>
                </div>
                <div className="columns small-12 lg_mt-1">
                  <p>Doctors told me that there was a possibility that I might lose my hearing and need hearing aids, like Zara (the inspirational cheetah you can read about in the Resources & Support section of this website). This worried me because I didn’t want to lose my hearing, and I was nervous about what people would think. I’m thankful for my friends because they don’t treat me differently. I’m just like any other 13-year-old girl when I’m with them.</p>
                </div>
              </Accordion.Expanded>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Drawer>Whenever you have thoughts or concerns about your health, what do you do that you’ve found helpful?</Accordion.Drawer>
              <Accordion.Expanded>
                <div className="columns large-9 small-12">
                  <p>I try not to worry too much, but there are definitely times when I get worried or feel overwhelmed. When I do feel this way, I try not to bottle up my emotions, which I tend to do, and instead talk to my dad. He’s been there for me throughout my whole journey and in those moments when I’m feeling down, I just need his and my family’s support. Also, similar to Zara, I practice deep breathing with my dad to help calm my nerves when I start to feel anxious. This helps me a lot, especially before doctor's appointments where I might get blood drawn—getting IVs and my blood drawn to this day is still scary for me, but I know it’s necessary in my follow-up appointments to ensure I’m still healthy and cancer-free.</p>
                </div>
                <div className="columns small-12 large-3 text-center large-text-right mt-1">
                  <img src={zaraMeditate} width="221" heigh="auto" alt="Zara meditating"/>
                </div>
              </Accordion.Expanded>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Drawer>What do you remember most from your days undergoing neuroblastoma treatment?</Accordion.Drawer>
              <Accordion.Expanded>
                <div className="columns small-12 large-3 text-center large-text-left">
                  <img src={zaraDance} width="158" heigh="auto" alt="Zara dancing"/>
                </div>
                <div className="columns small-12 large-9 mt-1">
                  <p>Because I was so young, I don’t have many specific memories about the time when I was being treated for neuroblastoma. Most of the time I felt normal and just tried to have fun like any other kid would do at that age. I would try to go outside as much as I could, watch a lot of movies, dance, and sing.</p>
                </div>
              </Accordion.Expanded>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Drawer>What would you tell other children who may be going through what you’ve gone through?</Accordion.Drawer>
              <Accordion.Expanded>
                <div className="columns lg_mt-3">
                  <p>I would tell them to stay strong. I think it’s really important to have fun while you can and to create and take in as many of the good memories as possible.</p>
                </div>
                <div className="columns small-12 large-3 text-center large-text-right mt-1">
                  <img src={zaraScooter} width="165" heigh="auto" alt="Zara dancing"/>
                </div>
              </Accordion.Expanded>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Drawer>What would you tell other families who are caring for children with neuroblastoma?</Accordion.Drawer>
              <Accordion.Expanded>
                <div className="columns small-12 large-3 text-center large-text-left">
                  <img src={zaraMom} width="214" heigh="auto" alt="Zara and her mom"/>
                </div>
                <div className="columns small-12 large-9 lg_mt-2">
                  <p>I would tell families to also stay strong for themselves but also for their kids. It’s really important to be supportive and just be there for them. I understand that a lot of the time family can’t be around 24/7, so it’s even more important when you are able to visit that you are strong in front of them.</p>
                </div>
              </Accordion.Expanded>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Drawer>Why is it important to be open and communicate with your support network?</Accordion.Drawer>
              <Accordion.Expanded>
                <div className="columns lg_mt-2 large-9 small-12">
                  <p>For me, I didn’t open up and share how I was feeling most of the time. I now know that it’s very important to do so. It’s not good to bottle up emotions because it affects your body and mind. Being vulnerable and open isn’t always easy, but it’s something that I continue to work on.</p>
                </div>
                <div className="columns small-12 large-3 text-center large-text-right">
                  <img src={zaraBed} width="214" heigh="auto" alt="Zara dreaming in bed"/>
                </div>
              </Accordion.Expanded>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Drawer>Have you had any lasting impact on your health because of treatment?</Accordion.Drawer>
              <Accordion.Expanded>
                <div className="columns small-12 large-4 text-center large-text-right">
                  <img src={zaraKono} width="278" heigh="auto" alt="Zara with Dr. Kono"/>
                </div>
                <div className="columns small-12 large-8">
                  <p>The way treatment affects people depends on the person. In my case, while I’m not fully aware of all the potential effects that can happen after treatment, I’ve noticed a few things. For example, when I was sick, I didn’t eat as much and when I got older and healthier, I started to gain weight, which made me feel bad about myself.</p>
                </div>
                <div className="columns small-12 lg_mt-1 mt-1">
                  <p>I also started to realize that my body parts were growing faster than they typically would and this made me feel weird compared to other kids my age. Doctors also said after I completed chemotherapy, I would grow hair a lot faster, and this is happening now. What I’ve talked about isn’t everything, however, and I know there are also certain things in the future that I will need to be on the lookout for as I continue to grow. Conversations with my dad and doctors help prepare me for this.</p>
                </div>
              </Accordion.Expanded>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Drawer>Do you ever get nervous leading up to your annual visits?</Accordion.Drawer>
              <Accordion.Expanded>
                <div className="columns small-12 large-8">
                  <p>Because there are potential lasting effects from treatment, I know that it’s important to check in with my doctors regularly. I don’t mind these visits, as they are not frequent—for me, it’s only once a year now since I entered the survivorship clinic. I sometimes get nervous that the cancer might come back or they might find something else, but I understand these follow-up visits are necessary to make sure I stay healthy and cancer-free.</p>
                </div>
                <div className="columns small-12 large-4 text-center large-text-right">
                  <img src={zaraKonoCu} width="300" heigh="auto" alt="Zara with Dr. Kono getting a checkup"/>
                </div>
              </Accordion.Expanded>
            </Accordion.Item>
          </Accordion>
          </div>
        </div>
      </Section>

      <Section id="resources" style={{backgroundColor: SassVars.honeydew}}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">Braving Neuroblastoma Resources</h3>
            <h4 className="h4 tc-viridian jost-med lg_mt-3">A series of four beautifully illustrated books</h4>
            <p>Each book highlights different stages of the high-risk neuroblastoma journey.</p>
          </div>
        </div>
        <div className="row row--inner align-justify lg_mt-3">

          <div className="columns large-12 small-12">
            <div className="row">
              <div className="columns small-12 large-shrink text-center">
                <img src={bigAdventures} alt="The Big Adventures of Little Skivolo" style={{maxWidth: `171px`}}/>
              </div>
              <div className="columns">
                <h3 className="h3 jost-med tc-viridian">The Big Adventures of Little Skivolo</h3>
                <p><em>The Big Adventures of Little Skivolo</em> helps families understand diagnosis and treatment.</p>
                <p className="lg_mt-2">
                  <a href="/BigAdventures_English.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="The Big Adventures of Little Skivolo (ENG)" target="_blank" rel="noreferrer" className="btn--link no-ext">Download in English <FontAwesomeIcon icon={faDownload}/></a>
                  <a href="/BigAdventures_Spanish.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="The Big Adventures of Little Skivolo (SPA)" target="_blank" rel="noreferrer" className="btn--link no-ext">Descargar en Español <FontAwesomeIcon icon={faDownload}/></a>
                  <a href="/BigAdventures_French.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="The Big Adventures of Little Skivolo (FRE)" target="_blank" rel="noreferrer" className="btn--link no-ext">Télécharger en Français <FontAwesomeIcon icon={faDownload}/></a>
                </p>
              </div>
            </div>
          </div>

          <div className="columns large-12 small-12 lg_mt-4 mt-4">
            <div className="row">
              <div className="columns small-12 large-shrink text-center">
                <img src={antiBodyBook} alt="The Big Adventures of Little Skivolo" style={{maxWidth: `171px`}}/>
              </div>
              <div className="columns">
                <h3 className="h3 jost-med tc-viridian">The Next Big Adventure of Little Skivolo</h3>
                <p><em>The Next Big Adventure of Little Skivolo</em> focuses specifically on the antibody therapy phase of treatment.</p>
                <p className="lg_mt-2">
                  <a href="/Next-Big-Adventures-English.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="The Next Big Adventure of Little Skivolo (ENG)" target="_blank" rel="noreferrer" className="btn--link no-ext">Download in English <FontAwesomeIcon icon={faDownload}/></a>
                  <a href="/Next-Big-Adventures-Spanish.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="The Next Big Adventure of Little Skivolo (SPA)" target="_blank" rel="noreferrer" className="btn--link no-ext">Descargar en Español <FontAwesomeIcon icon={faDownload}/></a>
                  <a href="/Next-Big-Adventures-French.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="The Next Big Adventure of Little Skivolo (FRE)" target="_blank" rel="noreferrer" className="btn--link no-ext">Télécharger en Français <FontAwesomeIcon icon={faDownload}/></a>
                </p>
              </div>
            </div>
          </div>

          <div className="columns large-12 small-12 lg_mt-4 mt-4">
            <div className="row">
              <div className="columns small-12 large-shrink text-center">
                <img src={bookOfFun} alt="The Big Adventures of Little Skivolo" style={{maxWidth: `171px`}}/>
              </div>
              <div className="columns">
                <h3 className="h3 jost-med tc-viridian">Little Skivolo’s Big Book of Fun</h3>
                <p><em>Little Skivolo’s Big Book of Fun</em>, an activity book to help entertain kids and families during hospital stays.</p>
                <p className="lg_mt-2">
                  <a href="/big-book-english.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="Little Skivolo’s Big Book of Fun (ENG)" target="_blank" rel="noreferrer" className="btn--link no-ext">Download in English <FontAwesomeIcon icon={faDownload}/></a>
                  <a href="/big-book-french.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="Little Skivolo’s Big Book of Fun (FRE)" target="_blank" rel="noreferrer" className="btn--link no-ext">Télécharger en Français <FontAwesomeIcon icon={faDownload}/></a>
                </p>
              </div>
            </div>
          </div>

          <div className="columns large-12 small-12 lg_mt-4 mt-4">
            <div className="row">
              <div className="columns small-12 large-shrink text-center">
                <img src={zara} alt="Zara Takes Off" style={{maxWidth: `171px`}}/>
              </div>
              <div className="columns">
                <h3 className="h3 jost-med tc-viridian">Zara Takes Off</h3>
                <p><em>Zara Takes Off</em> inspires hope and encouragement as families transition to life after treatment. <strong>This new release includes a personal foreword penned by Leah, sharing details of her personal journey.</strong></p>
                <p className="lg_mt-2">
                  <a href="/Zara_Survivorship_Book_and_Foreword_PDF.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="Zara Takes Off (ENG)" target="_blank" rel="noreferrer" className="btn--link no-ext">Download in English <FontAwesomeIcon icon={faDownload}/></a>
                  <a
                    href="/Zara_Takes_Off_Spanish.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="Zara Takes Off (SPA)"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Descargar en Español <FontAwesomeIcon icon={faDownload} />
                  </a>
                  <a
                    href="/Zara_Takes_Off_French.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="Zara Takes Off (FRE)"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Télécharger en Français{" "}
                    <FontAwesomeIcon icon={faDownload} />
                  </a>
                </p>
              </div>
            </div>
          </div>

        </div>
        <div className="row row--inner align-middle lg_mt-4">
          <div className="columns">
            <p><Link to="/resources/downloadable-resources/">Find even more resources on the Downloadable Resources & Videos page &rsaquo; </Link></p>
          </div>
        </div>
      </Section>


      <InteriorPageFooter>

        <UpNextCard supressTopic="true" topic="Explore more of neuroblastoma-info.com" title="Find the information, resources, and support you need" lead="Neuroblastoma-info.com is a family-friendly website that includes a comprehensive library of information and resources for every step in the neuroblastoma journey." url="/" />


      </InteriorPageFooter>

    </Layout>
  )
}

export default BravingNeuroBlastomaPage
