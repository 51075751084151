import React from "react"
import { Link } from "gatsby"

const SupportSubmenu = () => {
  return (
    <>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Mental Health and the Childhood Cancer Journey" activeClassName="isActive" partiallyActive={true} to="/support/mental-health/">Mental Health and the Childhood Cancer Journey <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Caring for the Caregiver" activeClassName="isActive" to="/support/caregiver-support/">Caring for the Caregiver <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Braving NeuroBLASToma" activeClassName="isActive" partiallyActive={true} to="/support/braving-neuroblastoma/">Braving Neuroblastoma <span className="np-arrow">&rsaquo;</span></Link></li>
    </>
  )
}

export default SupportSubmenu
